import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  setLayoutHeader,
  useDeleteContestMeMutation,
  useInspectContestQuery,
  useRecalculateContestMutation,
  useSendContestInvitationsMutation,
  useSetFeedbackMutation,
} from 'store';

import { getImage } from 'utils/asset';
import { useAppDispatch } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { Category, ContentWidth, FlexContainer, MediumGap } from 'styles';
import * as routes from 'router/routes';

import Icon, { IconType } from 'components/UI/Icon';
import WellrCoins from 'components/UI/WellrCoins';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import EmptyState from 'components/UI/EmptyState';

import {
  Byline,
  CategoryHeader,
  ContestInfoContainer,
  ContestInfoItem,
  DarkModeCol,
  DarkModeContainer,
  TeamsContainer,
} from './styles';
import CompetitionStatus from 'components/fragments/CompetitionStatus';
import UploadTeamsForm from 'components/forms/UploadTeamsForm/UploadTeamsForm';
import UpdateCompetitionParticipantModal from 'components/modals/UpdateCompetitionParticipantModal';
import UpdateCompetitionTeamModal from 'components/modals/UpdateCompetitionTeamModal';
import Button from 'components/UI/Button';
import ContentModal from 'components/modals/ContentModal';
import RichText from 'components/fragments/RichText';
import ExpandableCardList from 'components/UI/Cards/ExpandableCardList';
import { toast } from 'react-hot-toast';
import AddCompetitionParticipantModal from 'components/modals/AddCompetitionParticipantModal';
import { formatDateTime, getDateTime } from 'utils/date';
import useModal from 'hooks/useModal';
import AdminDarkModeModal from 'components/modals/AdminDarkModeModal';
import StaticCard from 'components/UI/Cards/StaticCard';
import ListMenu from 'components/UI/ListMenu';
import ButtonWithIcon from 'components/UI/ButtonWithIcon';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import UpdateCompetitionHealthQuizBonusModal from 'components/modals/UpdateCompetitionHealthQuizBonusModal';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import ChatModal from 'components/modals/ChatModal';
import UpdateCompetitionDatesModal from 'components/modals/UpdateCompetitionDatesModal';
import CreateTeamAdminModal from 'components/modals/CreateTeamAdminModal';

const ContestInfoCol: FC<{
  label: string;
  value: string | number;
  onEdit?: () => void;
}> = ({ label, value, onEdit }) => (
  <ContestInfoItem>
    <Heading tag={Tag.H5}>{label}</Heading>
    <FlexContainer gap={4}>
      <div>{value}</div>
      {onEdit != null && (
        <div style={{ cursor: 'pointer' }} onClick={onEdit}>
          <Icon type={IconType.Edit} size={20} color="blue" />
        </div>
      )}
    </FlexContainer>
  </ContestInfoItem>
);

const AdminCompetitionView: FC = () => {
  const intl = useIntl();
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastContestInspectFetch, setLastContestInspectFetch] = useState(0);
  const { slug } = useParams();
  const getText = useLocalizedText();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    modalOpen: darkModeModalOpen,
    onCloseModal: onCloseDarkModeModal,
    onOpenModal: onOpenDarkModeModal,
  } = useModal();

  const {
    modalOpen: isTeamModalOpen,
    onCloseModal: onCloseTeamModal,
    onOpenModal: onOpenTeamModal,
  } = useModal();

  const {
    modalOpen: confirmDeleteOpen,
    onCloseModal: onCloseDeleteModal,
    onOpenModal: onOpenDeleteModal,
  } = useModal();

  const {
    modalOpen: isChangeDateOpen,
    onCloseModal: onCloseChangeDateModal,
    onOpenModal: onOpenChangeDateModal,
  } = useModal();


  //State
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(
    null
  );
  const [instructionsModalOpen, setInstructionsModalOpen] =
    useState<boolean>(false);

  const onOpenInstructionModal = useCallback(
    () => setInstructionsModalOpen(true),
    []
  );

  const onCloseInstructionModal = useCallback(
    () => setInstructionsModalOpen(false),
    []
  );

  const {
    modalOpen: bonusModalOpen,
    onCloseModal: onCloseBonusModal,
    onOpenModal: onOpenBonusModal,
  } = useModal();

  const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] =
    React.useState(false);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] =
    React.useState(false);
  const [isActiveContest, setIsActiveContest] =
    React.useState(true);

  const [isParticipantModalOpen, setIsParticipantModalOpen] =
    React.useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = React.useState<
    string | null
  >(null);

  // Hooks
  const [deleteCompetition, deleteResult] = useDeleteContestMeMutation();
  const { data, isLoading, fulfilledTimeStamp } = useInspectContestQuery(
    {
      contestId: slug as string,
    },
    {
      refetchOnMountOrArgChange: 20,
      refetchOnFocus: lastContestInspectFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
      skip: deleteResult.isSuccess || deleteResult.isLoading,
    }
  );

  const [recalculateContest, recalculateResult] =
    useRecalculateContestMutation();

  const [setFeedbackSurveyEnabled, setFeedbackSurveyResult] =
    useSetFeedbackMutation();

  useEffect(() => {
    setLastContestInspectFetch(Date.now())
  }, [fulfilledTimeStamp]);

  useEffect(() => {
    if (data?.contestInspectDetails?.contestStop === null || data?.contestInspectDetails?.contestStop === undefined) {
      setIsActiveContest(true);
    } else {
      const contestStopDate = new Date(data.contestInspectDetails.contestStop!).getTime();
      const currentDate = new Date().getTime();
      setIsActiveContest(contestStopDate > currentDate);
    }
  }, [data?.contestInspectDetails?.contestStop]);

  useEffect(() => {
    if (recalculateResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'recalculateContestSuccess',
          defaultMessage: 'Contest will be recalculated on next aggregation',
          description:
            'Toast success text when contest recalculate triggered on admin competition view page',
        })
      );
    }
    if (setFeedbackSurveyResult.isSuccess && !data?.contestInspectDetails.isFeedbackSurveyEnabled) {
      toast.success(
        intl.formatMessage({
          id: 'plannedSendingFeedbackFormSuccess',
          defaultMessage: 'Feedback form will be sent after competition',
          description: 'Toast success text when feedback form will be sent after competition on admin competition view page',
        }))
    }
    else if (setFeedbackSurveyResult.isSuccess && data?.contestInspectDetails.isFeedbackSurveyEnabled) {
      toast.success(
        intl.formatMessage({
          id: 'plannedNotSendingFeedbackFormSuccess',
          defaultMessage: 'Feedback form will not be sent after competition',
          description: 'Toast success text when feedback form will not be sent after competition on admin competition view page',
        }))
    };
  }, [intl, recalculateResult.isSuccess, setFeedbackSurveyResult.isSuccess]);

  const [sendContestInvitations, sendContestInvitationResult] =
    useSendContestInvitationsMutation();

  useEffect(() => {
    if (sendContestInvitationResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'sendContestInvitationsSuccess',
          defaultMessage: 'Invitations successfully sent',
          description:
            'Toast success text when successfully sent contest invitations on admin competition view page',
        })
      );
    }
  }, [intl, sendContestInvitationResult.isSuccess]);

  const selectedParticipant = useMemo(() => {
    if (data == null || selectedParticipantId === null) {
      return null;
    }
    const allUsers = [
      ...(data.contestInspectDetails.users ?? []),
      ...(data.contestInspectDetails.nonAcceptedUsers ?? []),
    ];
    return (
      allUsers.find(
        ({ contestUserId }) => contestUserId === selectedParticipantId
      ) ?? null
    );
  }, [data, selectedParticipantId]);

  const selectedTeam = useMemo(() => {
    if (!data?.contestInspectDetails?.teams || selectedTeamId === null) {
      return null;
    }
    const team = data.contestInspectDetails.teams.find(
      ({ contestTeamId }) => contestTeamId === selectedTeamId
    );
    if (!team) {
      return null;
    }
    return {
      contestTeamId: team.contestTeamId,
      description: team.description,
    };
  }, [data?.contestInspectDetails?.teams, selectedTeamId]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompetitionsTitle',
          defaultMessage: 'Competitions',
          description: 'Page title for admin competitions page',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.ADMIN_COMPETITIONS,
      })
    );
  }, [dispatch, intl]);

  const teamOptions = useMemo(
    () =>
      (data?.contestInspectDetails?.teams ?? []).map(
        ({ contestTeamId, description }) => ({
          value: contestTeamId,
          name: description ?? '-',
        })
      ),
    [data?.contestInspectDetails?.teams]
  );

  const onCloseModal = useCallback(() => {
    setIsParticipantModalOpen(false);
  }, []);
  const onCloseAddParticipantModal = useCallback(() => {
    setIsAddParticipantModalOpen(false);
  }, []);
  const onCloseAddTeamModal = useCallback(() => {
    setIsAddTeamModalOpen(false);
  }, []);

  const teamsParticipantsContent = useMemo(() => {
    if (!data?.contestInspectDetails?.teams) {
      return null;
    }
    const onEditParticipant = (userId: string) => {
      setSelectedParticipantId(userId as string);
      setIsParticipantModalOpen(true);
    };

    const onEditTeam = (teamId: string) => {
      setSelectedTeamId(teamId as string);
      onOpenTeamModal();
    };

    return (
      <Fragment>
        {isActiveContest ? (
          <HeadingWithAdd
            title={intl.formatMessage({
              id: "competitionTeamMembersTitle",
              defaultMessage: "Teams & members",
              description: "competition team members title"
            })}
            addTitle={intl.formatMessage({
              id: 'competitionAddTeamtButton',
              defaultMessage: 'Add team',
              description: 'competition add team button',
            })}
            onAdd={() => setIsAddTeamModalOpen(true)}
          />
        ) : (
          <Heading tag={Tag.H4}>
            {intl.formatMessage({
              id: 'competitionTeamMembersTitle',
              defaultMessage: 'Teams & members',
              description: 'competition team members title',
            })
            }
          </Heading>
        )}
        <TeamsContainer>
          {data.contestInspectDetails.teams.map((team, index) => (
            <ExpandableCardList
              id={team.contestTeamId}
              key={index}
              title={team.description ?? ''}
              items={
                team.members?.map((member) => ({
                  id: member.contestUserId,
                  title: `${member.alias} (${member.email}) - ${member.accepted ? getDateTime(new Date(member.accepted)) : ''}`,
                  description:
                    member.steps +
                    ' ' +
                    intl.formatMessage({
                      id: 'activitiesUnitText',
                      defaultMessage: 'steps',
                      description: 'Unit text for activities',
                    }),
                })) ?? []
              }
              onEditItem={onEditParticipant}
              onEdit={onEditTeam}
            />
          ))}
        </TeamsContainer>
        <MediumGap />
        {isActiveContest ? (
          <HeadingWithAdd
            title={intl.formatMessage({
              id: 'competitionParticipantsTitle',
              defaultMessage: 'Participants',
              description: 'competition participants title',
            })}
            addTitle={intl.formatMessage({
              id: 'competitionAddParticipantButton',
              defaultMessage: 'Add participant',
              description: 'competition add participant button',
            })}
            onAdd={() => setIsAddParticipantModalOpen(true)}
          />
        ) : (
          <Heading tag={Tag.H4}>
            {intl.formatMessage({
              id: 'competitionParticipantsTitle',
              defaultMessage: 'Participants',
              description: 'competition participants title',
            })
            }
          </Heading>
        )}
        <ExpandableCardList
          id={'competition_participants'}
          title={intl.formatMessage(
            {
              id: 'competitionParticipansCountTitle',
              defaultMessage: 'Accepted participants ({count})',
              description: 'Participant count title',
            },
            { count: data.contestInspectDetails.users?.length ?? 0 }
          )}
          items={
            data.contestInspectDetails.users?.map((member) => ({
              id: member.contestUserId,
              title: `${member.alias} (${member.email})`,
              description:
                member.steps +
                ' ' +
                intl.formatMessage({
                  id: 'activitiesUnitText',
                  defaultMessage: 'steps',
                  description: 'Unit text for activities',
                }),
            })) ?? []
          }
          onEditItem={onEditParticipant}
        />
        {data.contestInspectDetails.nonAcceptedUsers != null &&
          data.contestInspectDetails.nonAcceptedUsers.length > 0 && (
            <>
              <br />
              <ExpandableCardList
                id={'competition_nonaccepted_participants'}
                title={intl.formatMessage(
                  {
                    id: 'competitionNonAcceptedParticipansCountTitle',
                    defaultMessage: 'Non-accepted participants ({count})',
                    description: 'Participant count title',
                  },
                  {
                    count:
                      data.contestInspectDetails.nonAcceptedUsers?.length ?? 0,
                  }
                )}
                items={
                  data.contestInspectDetails.nonAcceptedUsers?.map(
                    (member) => ({
                      id: member.contestUserId,
                      title: member.email ?? '',
                      description: '',
                    })
                  ) ?? []
                }
                onEditItem={onEditParticipant}
              />
            </>
          )}
        <UpdateCompetitionParticipantModal
          isOpen={isParticipantModalOpen}
          onClose={onCloseModal}
          contestUser={selectedParticipant}
          teams={teamOptions}
        />
        <UpdateCompetitionTeamModal
          isOpen={isTeamModalOpen}
          onClose={onCloseTeamModal}
          contestTeam={selectedTeam}
        />
        <UpdateCompetitionDatesModal
          isOpen={isChangeDateOpen}
          onClose={onCloseChangeDateModal}
          id={slug as string}
          contestStop={data.contestInspectDetails.contestStop
            ? formatDateTime(
              new Date(data.contestInspectDetails.contestStop)
            )
            : '-'}
        />
      </Fragment>
    );
  }, [
    data?.contestInspectDetails.teams,
    data?.contestInspectDetails.users,
    data?.contestInspectDetails.nonAcceptedUsers,
    intl,
    isParticipantModalOpen,
    onCloseModal,
    selectedParticipant,
    teamOptions,
    isTeamModalOpen,
    onCloseChangeDateModal,
    isChangeDateOpen,
    onCloseTeamModal,
    selectedTeam,
    onOpenTeamModal,
  ]);

  const pinkCompetition = useMemo(() => {
    if (!data || !data.contestOut || !data.contestOut.title) {
      return false;
    }

    return Object.values(data.contestOut.title).some((text) =>
      text.toLowerCase().includes('pink')
    );
  }, [data]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageViewCompetitionEmptyState"
          defaultMessage="Competition not found"
          description="Empty state for view competition admin page"
        />
      </EmptyState>
    );
  }

  const {
    contestOut: { title, goals, wellrPoints, image, teams, id, totalAmount },
  } = data;

  return (
    <Fragment>
      <HeroSmall image={getImage(image)}>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageCompetitionCategoryTitle"
              defaultMessage="Competition"
              description="Competition category title"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
          <Byline>
            <WellrCoins count={wellrPoints} right />
          </Byline>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        <ContestInfoContainer>
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'inputStartDateLabel',
              defaultMessage: 'Start date',
              description: 'Label for start date input',
            })}
            value={
              data.contestInspectDetails.contestStart
                ? formatDateTime(
                  new Date(data.contestInspectDetails.contestStart)
                )
                : '-'
            }
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'inputStopDateLabel',
              defaultMessage: 'Stop date',
              description: 'Label for stop date input',
            })}
            value={
              data.contestInspectDetails.contestStop
                ? formatDateTime(
                  new Date(data.contestInspectDetails.contestStop)
                )
                : '-'
            }
            onEdit={onOpenChangeDateModal}
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'contestLastAggregatedLabel',
              defaultMessage: 'Last aggregated',
              description: 'Label for contest last aggregated',
            })}
            value={
              data.contestInspectDetails.lastAggregated
                ? formatDateTime(
                  new Date(data.contestInspectDetails.lastAggregated)
                )
                : '-'
            }
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'contestTeamConfigurationLabel',
              defaultMessage: 'Team configuration',
              description: 'Label for contest team configuration',
            })}
            value={data.contestInspectDetails.teamConfiguration ?? '-'}
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'contestPendingRecalculateLabel',
              defaultMessage: 'Pending recalculate',
              description: 'Label for contest pending recalculate',
            })}
            value={
              data.contestInspectDetails.pendingRecalculate
                ? intl.formatMessage({
                  id: 'yes',
                  description: '"Yes" label',
                  defaultMessage: 'Yes',
                })
                : intl.formatMessage({
                  id: 'no',
                  description: '"No" label',
                  defaultMessage: 'No',
                })
            }
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'contestCompletedHealthQuizBonusLabel',
              defaultMessage: 'Bonus from health quizzes',
              description: 'Label for contest completed health quiz bonus',
            })}
            value={
              data.contestInspectDetails.bonusStepsOnCompletedHealthQuizes ?? 0
            }
            onEdit={onOpenBonusModal}
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'feedbackFormLabel',
              defaultMessage: 'Feedback form',
              description: 'Label for feedback form',
            })}
            value={
              data.contestInspectDetails.isFeedbackSurveyEnabled
                ? intl.formatMessage({
                  id: 'yes',
                  description: '"Yes" label',
                  defaultMessage: 'Yes',
                })
                : intl.formatMessage({
                  id: 'no',
                  description: '"No" label',
                  defaultMessage: 'No',
                })
            }
          />
          <ContestInfoCol
            label={intl.formatMessage({
              id: 'competitionParticipantsTitle',
              defaultMessage: 'Participants',
              description: 'competition participants title',
            })}
            value={
              data.contestInspectDetails.acceptedUserCount ?? '-'
            }
          />
          <UpdateCompetitionHealthQuizBonusModal
            contestId={slug as string}
            currentBonus={
              data.contestInspectDetails.bonusStepsOnCompletedHealthQuizes ?? 0
            }
            isOpen={bonusModalOpen}
            onClose={onCloseBonusModal}
          />
        </ContestInfoContainer>
        <div style={{ display: 'flex' }}>
          <Button
            color="blue"
            background="transparent"
            border="borderDark"
            onClick={onOpenInstructionModal}
          >
            <FormattedMessage
              id="competitionRulesButton"
              defaultMessage="Rules and instructions"
              description="Competition rules button"
            />
          </Button>
          <ContentModal
            title={intl.formatMessage({
              id: 'pageCompetitionInstructionsTitle',
              defaultMessage: 'Rules & instructions',
              description: 'Competitions page instructions title',
            })}
            isOpen={instructionsModalOpen}
            onClose={onCloseInstructionModal}
          >
            {data.contestOut.description ? (
              <RichText>{data.contestOut.description}</RichText>
            ) : (
              '-'
            )}
          </ContentModal>
        </div>
        <br />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="competitionAdminDarkModeTitle"
            defaultMessage="Dark mode"
            description="competition progress title"
          />
        </Heading>
        <StaticCard padding>
          {data.contestInspectDetails.darkModeStart != null &&
            data.contestInspectDetails.darkModeStop != null ? (
            <DarkModeContainer>
              <DarkModeCol>
                <Heading tag={Tag.H5}>
                  {intl.formatMessage({
                    id: 'inputStartDateLabel',
                    defaultMessage: 'Start date',
                    description: 'Label for start date input',
                  }) + ':'}
                </Heading>
                <div>
                  {formatDateTime(
                    new Date(data.contestInspectDetails.darkModeStart)
                  )}
                </div>
              </DarkModeCol>
              <DarkModeCol>
                <Heading tag={Tag.H5}>
                  {intl.formatMessage({
                    id: 'inputStopDateLabel',
                    defaultMessage: 'Stop date',
                    description: 'Label for stop date input',
                  }) + ':'}
                </Heading>
                <div>
                  {formatDateTime(
                    new Date(data.contestInspectDetails.darkModeStop)
                  )}
                </div>
              </DarkModeCol>
            </DarkModeContainer>
          ) : (
            <span>
              <FormattedMessage
                id="competitionAdminDarkModeDisabledText"
                defaultMessage="Dark mode is not enabled"
                description="admin competition dark mode not enabled text"
              />
            </span>
          )}
          <br />
          <Button color="white" background="blue" onClick={onOpenDarkModeModal}>
            <FormattedMessage
              id="competitionAdminDarkModeButton"
              defaultMessage="Configure dark mode"
              description="competition set dark mode button"
            />
          </Button>
          <AdminDarkModeModal
            start={data.contestInspectDetails.darkModeStart ?? ''}
            stop={data.contestInspectDetails.darkModeStop ?? ''}
            isOpen={darkModeModalOpen}
            onClose={onCloseDarkModeModal}
            contestId={id}
          />
        </StaticCard>
        <MediumGap />
        {data.contestOut.canUploadTeams && (
          <>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="uploadTeamsFormTitle"
                defaultMessage="Upload teams"
                description="upload teams form title"
              />
            </Heading>
            <StaticCard padding>
              <UploadTeamsForm contestId={id} />
            </StaticCard>
            <MediumGap />
          </>
        )}
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="competitionProgressTitle"
            defaultMessage="Competition progress"
            description="competition progress title"
          />
        </Heading>
        <CompetitionStatus
          goals={goals}
          teams={teams}
          team={null}
          totalAmount={totalAmount}
          pinkCompetition={pinkCompetition}
        />
        <MediumGap />
        <ListMenu
          menu={[
            {
              id: 2,
              text: intl.formatMessage({
                id: 'menuCompetitionStatistics',
                defaultMessage: 'Competition statistics',
                description: 'List menu item title for competition statistics',
              }),
              link: `${routes.ADMIN_COMPETITIONS}/${slug}/statistics`,
              icon: IconType.BarChart,
            },
          ]}
        />
        <MediumGap />
        {teamsParticipantsContent}
        <MediumGap />
        <FlexContainer gap={16} flexDirection="column">
          <Button
            background="blue"
            color="white"
            onClick={() =>
              sendContestInvitations({ contestId: slug as string })
            }
            disabled={sendContestInvitationResult.isLoading}
          >
            <FormattedMessage
              id="competitionSendEmailInvitesButton"
              defaultMessage="Send email invitations to new participants"
              description="competition submit email invites button"
            />
          </Button>
          <Button
            background={!data.contestInspectDetails.isFeedbackSurveyEnabled ? 'blue' : 'orange'}
            color="white"
            onClick={() => setFeedbackSurveyEnabled({ contestId: slug as string })}
            disabled={setFeedbackSurveyResult.isLoading}
          >
            {!data.contestInspectDetails.isFeedbackSurveyEnabled ?
              <FormattedMessage
                id="sendFeedbackFormAfterCompetition"
                defaultMessage="Send feedback form after competition"
                description="competition send feedback form button"
              />
              :
              <FormattedMessage
                id="sendNotFeedbackFormAfterCompetition"
                defaultMessage="Do not send feedback form after competition"
                description="Do not send feedback form after competition button" />
            }
          </Button>
          <Button
            background="blue"
            color="white"
            onClick={() => recalculateContest({ contestId: slug as string })}
            disabled={recalculateResult.isLoading}
          >
            <FormattedMessage
              id="competitionTriggerRecalculateButton"
              defaultMessage="Recalculate competition"
              description="admin competition trigger recalculation button"
            />
          </Button>
          <br />
          <ButtonWithIcon
            icon={IconType.Trash}
            background="white"
            color="pink"
            border="pink"
            onClick={onOpenDeleteModal}
            disabled={recalculateResult.isLoading}
          >
            <FormattedMessage
              id="competitionDelete"
              defaultMessage="Delete competition"
              description="admin delete competition button label"
            />
          </ButtonWithIcon>
        </FlexContainer>
        <AddCompetitionParticipantModal
          contestId={id}
          isOpen={isAddParticipantModalOpen}
          onClose={onCloseAddParticipantModal}
          teams={teamOptions}
        />
        <CreateTeamAdminModal
          contestId={id}
          isOpen={isAddTeamModalOpen}
          onClose={onCloseAddTeamModal}
        />
        <ConfirmActionPopup
          isOpen={confirmDeleteOpen}
          onConfirm={async () => {
            await deleteCompetition({ contestId: slug as string }).unwrap();
            navigate(-1);
          }}
          isLoading={deleteResult.isLoading}
          onAbort={onCloseDeleteModal}
        >
          <>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="deleteCompetitionConfirmHeading"
                defaultMessage="Delete competition"
                description="Heading text in the delete competition popup"
              />
            </Heading>
            <FormattedMessage
              id="deleteCompetitionConfirmBody"
              defaultMessage="Are you sure you want to delete this competition?"
              description="Body text in the delete competition popup"
            />
          </>
        </ConfirmActionPopup>
      </ContentWidth>
      <ChatModal
        unreadMessages={{
          contest: 0,
        }}
        rooms={
          data?.contestOut.id
            ? [
              {
                title: '',
                chatId: data?.contestOut.id,
              },
            ]
            : []
        }
      />
    </Fragment>
  );
};

export default AdminCompetitionView;
