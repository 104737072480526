import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useDeleteUserInviteMutation, useFinalizeUserInviteMutation } from 'store';
import * as routes from 'router/routes';

import ConfirmRegisterInviteForm from 'components/forms/ConfirmRegisterInviteForm';
import Heading, { Tag } from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import Loader from 'components/UI/Loader';
import CoverHeader from 'components/UI/CoverHeader';

import { CoverContent, Description } from 'styles';
import { useLanguageFromQuery } from 'hooks/useLanguageFromQuery';
import { InviteUserQuery } from 'models';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';

const ConfirmRegisterInvite: FC = () => {
  // Hooks
  const intl = useIntl();
  const [finalizeUserInvite, { isLoading, isSuccess }] =
    useFinalizeUserInviteMutation();
  const [deleteUserInvite, { isSuccess: deleteSuccess }] = useDeleteUserInviteMutation();
  const { languageLoaded } = useLanguageFromQuery();

  // State for confirmation popup
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState<InviteUserQuery | null>(null);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'confirmRegisterInviteSuccess',
          defaultMessage: 'Invite registration was successful',
          description: 'Confirm register invite success message"',
        })
      );
    }
    if (deleteSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'removeRegisterInviteSuccess',
          defaultMessage: 'Registration removed successfully. If this was a mistake or you want to register again, contact your administrator or email us at contact@wellr.se.',
          description: 'Remove register invite success message"',
        }),
        {
          duration: 15000,
        }
      );
    }
  }, [deleteSuccess, intl, isSuccess]);

  const handleDeny = (data: InviteUserQuery) => {
    setInviteToDelete(data);
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (inviteToDelete) {
      await deleteUserInvite(inviteToDelete);
      setShowConfirmDelete(false);
    }
  };

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return (
      <ConfirmRegisterInviteForm
        submitForm={finalizeUserInvite}
        deny={handleDeny}
      />
    );
  }, [finalizeUserInvite, isLoading]);

  // Redirect to login
  if (isSuccess) {
    return <Navigate to={routes.REGISTER_COMPLETE} />;
  }
  if (deleteSuccess) {
    return <Navigate to={routes.LOGIN} />;
  }

  // Prevent language flickering
  if (!languageLoaded) {
    return <Loader padding />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="confirmRegisterInviteTitle"
            defaultMessage="Confirm registration"
            description="Confirm register invite page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="confirmRegisterInviteDescription"
            defaultMessage="Almost done! You just need to enter some more information about yourself."
            description="Confirm register invite page description"
          />
        </Description>
        {content}
      </CoverContent>
      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={handleConfirmDelete}
        onAbort={() => setShowConfirmDelete(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="denyGDPRConfirmHeading"
              defaultMessage="Confirm GDPR Denial"
              description="Heading text in the GDPR denial confirmation popup"
            />
          </Heading>
          <FormattedMessage
            id="denyGdprConfirmBody"
            defaultMessage="This will remove your registration. If this was a mistake, press cancel and continue to accept GDPR."
            description="Body text in the delete head office popup"
          />
        </>
      </ConfirmActionPopup>
    </BackgroundCover>
  );
};

export default ConfirmRegisterInvite;