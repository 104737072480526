import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { MediaDisplay } from 'styles';
import { QuizQuestion, QuizQuestionActive } from 'models';
import {
  localizationSelector,
  useAnswerPulseQuizQuestionMutation,
  useSubmitPulseQuizMutation,
  useGetPulseQuizQuery,
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getActiveQuestionById } from 'utils/quiz';
import { addOrRemoveFromArray } from 'utils/array';
import { getCategoryIcon } from 'utils/asset';
import * as routes from 'router/routes';

import IconButton from 'components/UI/IconButton';
import SliderField from 'components/UI/SliderField';
import QuizAlternativeCard from 'components/cards/QuizAlternativeCard';
import BackgroundCover from 'components/UI/BackgroundCover';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';

import {
  AlternativeGrid,
  ProgressLine,
  QuizHeader,
  QuizBody,
  QuestionContainer,
  QuestionHeader,
  QuestionText,
  HeaderCenterCol,
  HeaderLeftCol,
  HeaderRightCol,
  NavButtons,
  NavButton,
  NavText,
  Content,
  HealthCategory,
} from './styles';
import QuizResults from '../QuizResults';

const Quiz: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const { pulseQuizInstanceId } = useParams();
  const [questionId] = useQuery('questionId');
  const { language } = useAppSelector(localizationSelector);
  const quiz = useGetPulseQuizQuery({
    pulseQuizInstanceId,
    language: language?.languageCode,
  });
  const [answerQuestion, answerResult] = useAnswerPulseQuizQuestionMutation();
  const [submitQuiz, submitResult] = useSubmitPulseQuizMutation();

  // Data
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  useEffect(() => {
    setAnswerSubmitted(false);
  }, [questionId]);

  const { data, isLoading } = useMemo(() => {
    return {
      data: answerResult.data || quiz.data || null,
      isLoading: submitResult.isLoading || quiz.isLoading,
      isError: submitResult.isError || answerResult.isError || quiz.isError,
      error: submitResult.error || answerResult.error || quiz.error,
    };
  }, [submitResult, answerResult, quiz]);

  // Active data
  const activeData = useMemo<QuizQuestionActive | null>(() => {
    if (!quiz.data) {
      return null;
    }
    return getActiveQuestionById(quiz.data.progress.questions, questionId);
  }, [quiz.data, questionId]);

  const defaultResponse = useMemo(() => {
    if (activeData?.question.type === 'sliderQuestion') {
      const { minPoints, maxPoints } = activeData.question;
      return Math.round((maxPoints.value - minPoints.value) / 2);
    }
    return undefined;
  }, [activeData]);

  // Callbacks
  const onSetAnswer = useCallback(
    (
      question: QuizQuestion,
      points: number,
      alternativeId?: string,
      onSubmitted?: () => void
    ) => {
      const { id, type, multipleAnswersAllowed } = question;
      if (type === 'alternativeQuestion' && alternativeId) {
        answerQuestion({
          pulseQuizInstanceId,
          answer: {
            questionId: id,
            alternativeIds: multipleAnswersAllowed
              ? addOrRemoveFromArray(question.answers, alternativeId)
              : [alternativeId],
            points,
          },
          language: language?.languageCode,
        }).then(onSubmitted);
      }
      if (type === 'sliderQuestion') {
        answerQuestion({
          pulseQuizInstanceId,
          answer: {
            questionId: id,
            points,
          },
          language: language?.languageCode,
        }).then(onSubmitted);
      }
      setAnswerSubmitted(true);
    },
    [answerQuestion, pulseQuizInstanceId, language]
  );

  // Set question
  const onSetQuestion = useCallback(
    (id?: string | null) => {
      if (id) {
        navigate(
          `${routes.PULSE_QUIZ}/${pulseQuizInstanceId}?questionId=${id}`
        );
      }
    },
    [navigate, pulseQuizInstanceId]
  );

  // Previous question
  const onSetPrev = useCallback(
    () => onSetQuestion(activeData?.prevId),
    [onSetQuestion, activeData]
  );

  // Next question
  const onSetNext = useCallback(async () => {
    if (
      defaultResponse !== undefined &&
      !answerSubmitted &&
      activeData?.question.type === 'sliderQuestion'
    ) {
      const answerCallback = !activeData?.nextId
        ? () => {
            submitQuiz({ pulseQuizInstanceId, language: language?.languageCode });
          }
        : undefined;
      onSetAnswer(
        activeData.question,
        defaultResponse,
        undefined,
        answerCallback
      );
      if (!answerCallback) {
        onSetQuestion(activeData?.nextId);
        return;
      }
    } else if (!activeData?.nextId) {
      return submitQuiz({ pulseQuizInstanceId, language: language?.languageCode });
    }
    onSetQuestion(activeData?.nextId);
  }, [
    activeData?.nextId,
    activeData?.question,
    answerSubmitted,
    defaultResponse,
    onSetAnswer,
    onSetQuestion,
    pulseQuizInstanceId,
    submitQuiz,
    language
  ]);

  // Close quiz
  const onClose = useCallback(() => navigate(routes.TESTS), [navigate]);

  // Active question
  const activeQuestion = useMemo(() => {
    if (!activeData || !data) {
      return null;
    }
    const { count, question } = activeData;
    const { minPoints, maxPoints } = question;
    return (
      <div>
        <QuestionHeader>
          <FormattedMessage
            id="pageQuizQuestionCount"
            defaultMessage="Question {count}/{total}"
            description="Question count for quiz"
            values={{
              count,
              total: data.progress.questions.length,
            }}
          />
        </QuestionHeader>
        <QuestionText>{getText(question.text)}</QuestionText>
        {question.type === 'alternativeQuestion' ? (
          <AlternativeGrid>
            {question.alternatives.map((alt) => (
              <QuizAlternativeCard
                key={alt.id}
                text={getText(alt.text)}
                isActive={question.answers.includes(alt.id)}
                hasMultipleAnswers={question.multipleAnswersAllowed}
                onClick={() => onSetAnswer(question, alt.points, alt.id)}
              />
            ))}
          </AlternativeGrid>
        ) : null}
        {question.type === 'sliderQuestion' ? (
          <SliderField
            minValue={minPoints.value}
            maxValue={maxPoints.value}
            minText={getText(minPoints.description)}
            maxText={getText(maxPoints.description)}
            onChange={(points) => onSetAnswer(question, points)}
            defaultValue={defaultResponse}
          />
        ) : null}
      </div>
    );
  }, [activeData, data, getText, defaultResponse, onSetAnswer]);

  // Health category
  const healthCategory = useMemo(() => {
    if (!data) {
      return null;
    }
    const { healthCategory } = data.quizDefinition;
    if (!healthCategory) {
      return null;
    }
    const { title, icon } = healthCategory;
    const img = getCategoryIcon(icon, true);
    return (
      <Fragment>
        {img && <img src={img.src} alt={img.alt} color="white" />}
        {getText(title)}
      </Fragment>
    );
  }, [getText, data]);

  // Progress bar
  const progressBar = useMemo(() => {
    if (!data || !activeData) {
      return null;
    }
    const { index } = activeData;
    const { noQuestions } = data.progress;
    return Array.from(Array(noQuestions).keys()).map((i) => (
      <ProgressLine key={i} isActive={i === index} />
    ));
  }, [data, activeData]);

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader padding />;
    }

    // No data
    if (!data) {
      return (
        <EmptyState iconType={IconType.Health} padding inverted>
          <FormattedMessage
            id="pageQuizEmptyState"
            defaultMessage="Quiz not found"
            description="Empty state for quiz"
          />
        </EmptyState>
      );
    }

    return (
      <AnimatePresence mode="wait">
        <QuestionContainer
          key={questionId}
          transition={{ duration: 0.2 }}
          initial={{ y: 32, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -32, opacity: 0 }}
        >
          {activeQuestion}
        </QuestionContainer>
      </AnimatePresence>
    );
  }, [data, isLoading, questionId, activeQuestion]);

  // Left nav
  const leftNav = useMemo(() => {
    if (!activeData?.prevId) {
      return null;
    }
    return (
      <IconButton onClick={onSetPrev}>
        <NavButton left>
          <Icon type={IconType.Arrow} color="white" />
        </NavButton>
      </IconButton>
    );
  }, [onSetPrev, activeData]);

  // Right nav
  const rightNav = useMemo(() => {
    if (isLoading || !activeData) {
      return null;
    }
    return (
      <IconButton onClick={onSetNext}>
        <NavButton>
          {activeData.nextId ? (
            <Icon type={IconType.Arrow} color="white" />
          ) : (
            <NavText>
              <FormattedMessage
                id="pageQuizFinish"
                defaultMessage="Finish"
                description="Finish button for quiz"
              />
            </NavText>
          )}
        </NavButton>
      </IconButton>
    );
  }, [activeData, isLoading, onSetNext]);

  // Redirect to results
  if (submitResult.isSuccess) {
    return submitResult.data.assessmentResult == null ? (
      <Navigate to={routes.TESTS} />
    ) : (
      <QuizResults pulseQuizResult={submitResult.data.assessmentResult} />
    );
  }

  return (
    <BackgroundCover padding>
      <QuizHeader>
        <HeaderLeftCol>
          <HealthCategory>{healthCategory}</HealthCategory>
          <MediaDisplay breakpoint="m">
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderLeftCol>
        <HeaderCenterCol>{progressBar}</HeaderCenterCol>
        <HeaderRightCol>
          <MediaDisplay breakpoint="m" isLarger>
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderRightCol>
      </QuizHeader>
      <QuizBody>
        <Content>{content}</Content>
        <NavButtons>
          <div>{leftNav}</div>
          {rightNav}
        </NavButtons>
      </QuizBody>
    </BackgroundCover>
  );
};

export default Quiz;
