import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container } from 'components/Layout/styles';
import { ContentWidth } from 'styles';
import { usePageHeader } from 'hooks/usePageHeader';
import { useUseClearCacheMutation } from 'store';
import Button from 'components/UI/Button';
import toast from 'react-hot-toast';

const AdminMaintenance: React.FC = () => {
  const intl = useIntl();
  const [clearCache, clearCacheResult] = useUseClearCacheMutation();

  usePageHeader({
    title: intl.formatMessage({
      id: 'menuUtilities',
      defaultMessage: 'Maintenance',
      description: 'Page title for admin maintenance page',
    }),
  });

  const handleClearCache = async () => {
    try {
      await clearCache().unwrap();
    } catch (error) {
      toast.error(
        `Unexpected error occured.`
      );
    }
  };

  useEffect(() => {
    if (clearCacheResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'toastClearCacheSuccess',
          defaultMessage: 'Cache cleared successfully',
          description: 'Toast message for cache cleared successfully',
        })
      );

    }
  }, [clearCacheResult.isSuccess, intl]);
  return (
    <ContentWidth isSurface>
      <Container>
        <Button onClick={handleClearCache}>
          <FormattedMessage
            id="clearCache"
            defaultMessage="Clear Cache"
            description="Clear Cache"
          />
        </Button>
      </Container>
    </ContentWidth>
  );
};

export default AdminMaintenance;