import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useGetCompanyCommunicationQuery,
  useGetLatestArticlesQuery,
  useGetMembershipSettingsQuery,
  useGetStartPageQuery,
  useGetTotalPointsMyCompanyQuery,
  useGetTotalStepsMyCompanyQuery,
  useGetUserGreetingQuery,
  useGetUserQuery,
} from 'store';
import { ContentWidth, Gap, SmallGap } from 'styles';
import { getImage } from 'utils/asset';
import { getRefLink } from 'utils/link';

import ImageCard from 'components/UI/Cards/ImageCard';
import DragSlider from 'components/UI/DragSlider';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import HeroLarge from 'components/UI/Heros/HeroLarge';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import CompanyCommunication from 'components/fragments/CompanyCommunication';
import { HeroSliderWrap } from './styles';
import ActivitiesWeekly from 'components/fragments/ActivitiesWeekly';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import { SectionHeader, SmallButtonGrid } from 'pages/Compete/Competition/CompetitionOverview/styles';
import Button from 'components/UI/Button';
import OverviewTopList from 'components/fragments/OverviewTopList';
import { Activity, ActivityToplist, ActivityToplistQuery, TabMenuItem } from 'models';
import ContentModal from 'components/modals/ContentModal';
import TabMenu from 'components/UI/TabMenu';
import PaginationControlCard from 'components/UI/Cards/PaginationControlCard';
import ChallengeCard from 'components/cards/ChallengeCard';
import { useGetDailyTimedChallengeQuery } from 'store/timedChallengeService/timedChallengeService';
import { ChallengeType } from 'models/timedChallengeResult/timedChallengeResult';


const Overview: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const user = useGetUserQuery();
  const settings = useGetMembershipSettingsQuery();
  const greeting = useGetUserGreetingQuery({
    language: language?.languageCode,
  });
  const startPage = useGetStartPageQuery({ language: language?.languageCode });
  const latestArticles = useGetLatestArticlesQuery({
    limit: 6,
    language: language?.languageCode,
  });

  const { data: ccData } = useGetCompanyCommunicationQuery({
    language: language?.languageCode,
  });

  //state
  const [progress, setProgress] = useState<number>(0);
  const [highestSteps, setHighestSteps] = useState<number>(0);
  const [highestPoints, setHighestPoints] = useState<number>(0);
  const [toplistPages, setToplistPages] = useState<ActivityToplistQuery>({ page: 1, pageSize: 10 });
  const [activeTab, setActiveTab] = useState<number | string>(1);
  const [stepsView, setstepsView] = useState<boolean>(false);
  const [hasToplistNotification, setHasToplistNotification] = useState(false);
  const [topListInfoModalOpen, setTopListInfoModalOpen] =
    useState<boolean>(false);
  const onSetstepsView = useCallback(
    (value: boolean) => () => setstepsView(value),
    []
  );

  const { data: dailyChallenge, isLoading: dailyChallengeIsLoading } = useGetDailyTimedChallengeQuery();

  const { data: stepsToplist, isLoading } = useGetTotalStepsMyCompanyQuery({
    page: toplistPages?.page,
    pageSize: toplistPages?.pageSize,
  });

  const { data: pointsToplist } = useGetTotalPointsMyCompanyQuery({
    page: toplistPages?.page,
    pageSize: toplistPages?.pageSize,
  });

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: "pageMyHealthActivityTitle",
          defaultMessage: "Activity",
          description: "Section title for activity"
        }),
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabToplist',
          defaultMessage: 'Toplist',
          description: 'Tab item for toplist',
        }),
        hasNotification: hasToplistNotification,
      },
    ];
  }, []);

  const DailyChallengeCard = useMemo(() => {
    if (dailyChallengeIsLoading) {
      return <Loader color="blue" padding />;
    }

    if (!dailyChallenge || !user.data) {
      return null;
    }

    const {
      membership,
    } = user.data
    return (
      <ChallengeCard
        challenge={dailyChallenge}
        language={language?.languageCode}
        userId={membership.userId}
        membershipId={membership.id}
        progress={progress}
      />
    );
  }, [dailyChallenge, dailyChallengeIsLoading, language?.languageCode, progress, user.data]);

  // Set header
  useEffect(() => {
    if (user.data) {
      const { firstName } = user.data;
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage(
            {
              id: 'pageOverviewGreetings',
              defaultMessage: 'Hello {firstName}!',
              description: 'Overview page greetings text',
            },
            { firstName }
          ),
          inverted: true,
        })
      );
    }
  }, [dispatch, intl, user]);

  useEffect(() => {
    if (settings.data) {
      setHasToplistNotification(settings.data.showInToplistOverview === false);
    }
  }, [settings.data]);


  // Util functions
  const sortByProperty = (activityToplist: ActivityToplist | undefined, property: 'steps' | 'points') => {
    if (!activityToplist) {
      return [];
    }
    return [...activityToplist.userActivity].sort((a, b) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    });
  };
  const sortedBySteps = sortByProperty(stepsToplist, 'steps');
  const sortedByPoints = sortByProperty(pointsToplist, 'points');

  const handleActivitiesChange = useCallback((activities: Activity[]) => {
    if (!dailyChallenge || !activities) {
      return;
    }

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getActivitiesToday = (activities: Activity[], today: Date) => {
      return activities.filter((activity: Activity) => {
        const activityDate = new Date(activity.timestamp);
        activityDate.setHours(0, 0, 0, 0);
        return activityDate.getTime() === today.getTime();
      });
    };

    const calculateSteps = (activities: Activity[]) => {
      return activities.reduce((sum: number, activity: Activity) => {
        return sum + activity.value.stepsAwarded;
      }, 0);
    };

    const calculateMinutes = (activities: Activity[]) => {
      return activities.reduce((sum: number, activity: Activity) => {
        return sum + activity.value.numberOfUnits;
      }, 0);
    };

    const calculateProgress = (current: number, total: number) => {
      return (current / total) * 100;
    };

    const activitiesToday = getActivitiesToday(activities, today);
    const dailySteps = calculateSteps(activitiesToday);

    if (dailyChallenge?.challengeType === ChallengeType.Activity) {
      if (dailyChallenge?.allowAllActivities) {
        setProgress(calculateProgress(dailySteps, dailyChallenge?.amountToComplete));
      } else {
        const validDailyActivities = activitiesToday.filter((activity: Activity) => {
          return dailyChallenge?.allowedActivities.includes(activity.value.activitySlug);
        });

        const validDailySteps = calculateSteps(validDailyActivities);
        const validDailyMinutes = calculateMinutes(validDailyActivities);

        if (dailyChallenge.unit && dailyChallenge?.unit.slug === 'minute') {
          setProgress(calculateProgress(validDailyMinutes, dailyChallenge?.amountToComplete));
        } else {
          setProgress(calculateProgress(validDailySteps, dailyChallenge?.amountToComplete));
        }
      }
    }
  }, [dailyChallenge]);

  useEffect(() => {
    if (toplistPages.page === 1 && (stepsToplist?.userActivity.length ?? 0) > 0) {
      const newHighestSteps = sortedBySteps[0]?.steps!;
      // Only update if the new value is not 0
      if (newHighestSteps !== 0) {
        setHighestSteps(newHighestSteps);
      }
      setHighestPoints(sortedByPoints[0]?.points!);
    }
  }, [toplistPages.page, sortedBySteps, sortedByPoints]);

  const toplist = stepsView ? stepsToplist : pointsToplist;

  const paginationController = useMemo(() => {
    if (settings.data?.showInToplistOverview === false) {
      return null;
    }
    return (
      <PaginationControlCard
        currentPage={toplist?.currentPage ?? 1}
        pageSize={toplist?.pageSize ?? 10}
        qtyNotifications={toplist?.qtyUsers ?? 0}
        onBack={() => {
          if (!toplist || toplist?.currentPage < 1) return;
          setToplistPages({
            page: toplist.currentPage - 1,
            pageSize: toplist.pageSize
          });
        }}
        onForward={() => {
          if (!toplist || (toplist.currentPage) * toplist.pageSize > toplist.qtyUsers) return;
          setToplistPages({
            page: toplist.currentPage + 1,
            pageSize: toplist.pageSize
          });
        }}
      ></PaginationControlCard>
    );
  }, [toplist, settings.data?.showInToplistOverview]);

  // Navigate
  const onNavigate = useCallback(
    (link: string) => () => navigate(link),
    [navigate]
  );
  const onOpenTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(true),
    []
  );
  const onCloseTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(false),
    []
  );

  // Loading
  if (startPage.isLoading || latestArticles.isLoading || greeting.isLoading || isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!startPage.data || !latestArticles.data || !greeting.data) {
    return (
      <EmptyState iconType={IconType.Home} padding>
        <FormattedMessage
          id="pageOverviewEmptyState"
          defaultMessage="Home data not found"
          description="Empty state for overview page"
        />
      </EmptyState>
    );
  }

  const [{ startPageGroups }] = startPage.data;
  const firstStartPageGroup = startPageGroups[0];
  const restStartPageGroups = startPageGroups.slice(1);
  const { active, title, contentReference } = firstStartPageGroup;

  const umbrellaCompanyCommunicationContent =
    ccData?.umbrellaCompany?.companyCommunicationContent.filter(
      ({ displayToUser }) => displayToUser
    ) ?? [];

  // Show inspiration slider if no company communication content
  const noCompanyCommunicationContent =
    ccData?.company?.companyCommunicationContent?.length === 0 &&
    umbrellaCompanyCommunicationContent.length === 0;

  return (
    <ContentWidth>
      <Fragment>
        <HeroSliderWrap>
          <DragSlider id="hero-slider" slidesPerView={1} isHero>
            {greeting.data.map((item, index) => (
              <HeroLarge
                key={index}
                onClick={onNavigate(
                  getRefLink(item.referenceType, item.reference)
                )}
                buttonText={getText(item.buttonText)}
                title={getText(item.title)}
                text={getText(item.contentString)}
                image={getImage(item.image)}
              />
            ))}
          </DragSlider>
        </HeroSliderWrap>
        {active &&
          <ContentWidth isSurface style={{ marginTop: '0' }}>
            <Fragment>
              <Heading tag={Tag.H4}>{getText(title)}</Heading>
              <DragSlider id="slider">
                {contentReference.map((ref, j) => {
                  const { category, image, title, _type, slug } = ref;
                  return (
                    <ImageCard
                      key={`ref-${j}`}
                      category={getText(category.title)}
                      image={getImage(image)}
                      title={getText(title)}
                      onClick={onNavigate(getRefLink(_type, slug.current))}
                      size="small"
                    />
                  );
                })}
              </DragSlider>
            </Fragment>
          </ContentWidth>
        }
        {DailyChallengeCard}
        <SmallGap />
        <ContentWidth isSurface style={{ paddingBottom: '0px', marginBottom: '0px' }}>
          <TabMenu
            menuId="my-overview"
            menu={tabMenu}
            activeTab={activeTab}
            setActiveTab={setActiveTab} />
        </ContentWidth>
        <ContentWidth isSurface style={{ paddingBottom: '0px' }}>
          {activeTab === 2 ? (
            <>
              <SectionHeader>
                <Fragment>
                  <HeadingWithInfo
                    tag={Tag.H4}
                    onClickInfo={onOpenTopListInfoModal}
                  >
                    <FormattedMessage
                      id="pageOverviewMonthlyTopListTitle"
                      defaultMessage="Monthly top list"
                      description="Title for monthly top list section on overview page" />
                  </HeadingWithInfo>
                  <SmallButtonGrid>
                    <Button
                      color={stepsView ? 'blue' : 'white'}
                      border={stepsView ? 'borderDark' : 'blue'}
                      background={stepsView ? 'transparent' : 'blue'}
                      onClick={onSetstepsView(false)}
                      size="small"
                    >
                      <FormattedMessage
                        id="wellrPointsButton"
                        defaultMessage="Wellr points"
                        description="Competition top list for wellr point button" />
                    </Button>
                    <Button
                      color={stepsView ? 'white' : 'blue'}
                      border={stepsView ? 'blue' : 'borderDark'}
                      background={stepsView ? 'blue' : 'transparent'}
                      onClick={onSetstepsView(true)}
                      size="small"
                    >
                      <FormattedMessage
                        id="stepsButton"
                        defaultMessage="Steps"
                        description="Competition top list for steps button" />
                    </Button>
                  </SmallButtonGrid>
                </Fragment>
              </SectionHeader>

              <>
                {stepsView ? (
                  <>
                    <OverviewTopList
                      me={stepsToplist?.userActivity[0] as any}
                      users={sortedBySteps}
                      total={highestSteps}
                      page={toplistPages!.page}
                      points={false} />
                  </>
                ) : (
                  <>
                    <OverviewTopList
                      me={pointsToplist?.userActivity[0] as any}
                      users={sortedByPoints}
                      total={highestPoints}
                      page={toplistPages!.page}
                      points={true} />
                  </>
                )}
                {paginationController}

              </>
            </>
          ) : (
            <ContentWidth>
              <ActivitiesWeekly showLatest onActivitiesChange={handleActivitiesChange} />
            </ContentWidth>
          )
          }
          {restStartPageGroups.map(({ active, title, contentReference }, i) => {
            if (!active) {
              return null;
            }
            return (
              <Fragment key={i}>
                <SmallGap />
                <Heading tag={Tag.H4}>{getText(title)}</Heading>
                <DragSlider id={`slider-${i}`}>
                  {contentReference.map((ref, j) => {
                    const { category, image, title, _type, slug } = ref;
                    return (
                      <ImageCard
                        key={`ref-${j}`}
                        category={getText(category.title)}
                        image={getImage(image)}
                        title={getText(title)}
                        onClick={onNavigate(getRefLink(_type, slug.current))}
                        size="small" />
                    );
                  })}
                </DragSlider>
              </Fragment>
            );
          })}
          <Gap />
          {noCompanyCommunicationContent ? (
            <Fragment>
              <Heading tag={Tag.H4}>
                <FormattedMessage
                  id="pageOverviewInspirationTitle"
                  defaultMessage="Inspiration"
                  description="Title for inspiration section on overview page" />
              </Heading>
              <DragSlider id="inspiration" slidesPerView={2}>
                {latestArticles.data.map((item) => (
                  <ImageCard
                    key={item._id}
                    category={getText(item.category.title)}
                    title={getText(item.title)}
                    image={getImage(item.image)}
                    onClick={onNavigate(getRefLink('article', item.slug))}
                    size="large" />
                ))}
              </DragSlider>
              <Gap />
            </Fragment>
          ) : (
            <CompanyCommunication />
          )}

          <ContentModal
            isOpen={topListInfoModalOpen}
            onClose={onCloseTopListInfoModal}
            title={intl.formatMessage({
              id: 'pageCompetitionTopListTitle',
              defaultMessage: 'Top list',
              description: 'Title for top list section on competition page',
            })}
          >
            <FormattedMessage
              id="pageOverviewTopListInfo"
              defaultMessage={`
            You can see your status and a leaderboard for all users in your company.{NewLine}{NewLine}
             Wellr points:{NewLine}
              Here, your status is presented, including the percentage of your total points contributed by you.{NewLine}{NewLine}
               Steps:{NewLine}
             Here, a leaderboard is presented for individuals who have taken the most steps, along with the percentage of the total distance they have covered (if you have chosen to display it).`}
              description="Info text for top list on competition page"
              values={{ NewLine: <br /> }}
              tagName={'p'}
            />
          </ContentModal>
        </ContentWidth>
      </Fragment>
    </ContentWidth>
  );
};

export default Overview;